var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-breadcrumbs', {
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function fn() {
        return [_c('v-icon', [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }])
  }), _vm.alert ? _c('v-alert', {
    attrs: {
      "type": _vm.alert.status,
      "dismissible": "",
      "text": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]) : _vm._e(), _c('v-card', {
    staticClass: "pa-5 mt-5",
    attrs: {
      "elevation": "2"
    }
  }, [_c('v-card-title', [_vm._v(" Admin users "), _c('v-col', {
    staticClass: "text-right"
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "color": "primary",
      "to": {
        name: 'CreateAdmin'
      }
    }
  }, [_vm._v("Create admin")])], 1)], 1), _c('v-card-text', [_c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.users,
      "options": _vm.options,
      "server-items-length": _vm.totalUsers,
      "loading": _vm.loading
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "x-small": "",
            "icon": "",
            "to": {
              name: 'EditAdmin',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }], null, true)
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }