import axios from 'axios'
// var moment = require('moment')

export default {
  getUsers() {
    return axios.get('admins').then(res => {
      Object.entries(res.data.data.users.data).forEach(user => {
        user[1].status = user[1].status == 1 ? '表示中' : '非表示'
        user[1].full_name = user[1].last_name + ' ' + user[1].first_name
        // user[1].last_login_at = moment(user[1].last_login_at).format(
        //   'YYYY/MM/DD'
        // )
      })
      return res
    })
  },
  getUser(id) {
    return axios.get('admins/' + id)
  },
  createUser(params) {
    return axios.post('admins', params)
  },
  checkEmail(email) {
    return axios.get('email/check/' + email)
  },
  updateUSer(id, params) {
    return axios.put('admins/' + id, params)
  },
  deleteUser(id) {
    return axios.delete('admins/' + id)
  }
}
