<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-alert v-if="alert" :type="alert.status" dismissible text>
      {{ alert.message }}
    </v-alert>

    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        Admin users
        <v-col class="text-right">
          <v-btn small color="primary" :to="{ name: 'CreateAdmin' }"
            >Create admin</v-btn
          >
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="users"
          :options.sync="options"
          :server-items-length="totalUsers"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              x-small
              icon
              class="mr-2"
              :to="{ name: 'EditAdmin', params: { id: item.id } }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AdminAPI from '@/services/admin'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Home',
          disabled: false,
          to: { name: 'Dashboard' }
        },
        {
          text: 'Admin',
          disabled: false,
          to: { name: 'Admins' },
          exact: true
        },
        {
          text: 'List',
          disabled: true,
          href: ''
        }
      ],
      totalUsers: 0,
      users: [],
      loading: true,
      options: {},
      headers: [
        {
          text: 'ID',
          align: 'start',
          // sortable: false,
          value: 'id'
        },
        { text: 'Last name / First name', value: 'full_name' },
        { text: 'Email', value: 'email' },
        { text: 'Role', value: 'role' },
        { text: 'Status', value: 'status' },
        { text: 'Last login at', value: 'last_login_at' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.getUsersFromApi()
      },
      deep: true
    }
  },

  computed: {
    // ...mapState(['alert'])
    ...mapState({
      alert: state => state.alert,
      user: state => state.auth.user
    })
  },

  mounted() {
    if (this.alert != null) {
      setTimeout(() => {
        this.$store.commit('resetAlert')
      }, 5000)
    }
  },

  methods: {
    getUsersFromApi() {
      this.loading = true
      AdminAPI.getUsers().then(res => {
        this.users = res.data.data.users.data
        this.totalUsers = res.data.data.total
        this.loading = false
      })
    }
  }
}
</script>
